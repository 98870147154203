<template>
  <div class="imprint">
    <div class="imprint__title">Impressum</div>
    <div class="imprint__content">
      <div class="imprint__left">
        <p>LobbySpace GmbH<br />Ballindamm 3<br />20095 Hamburg</p>
        <p>
          <b>vertreten durch ihren Geschäftsführer:</b><br />Herr Samuel Härtl
        </p>
        <p><b>E-Mail</b><br />hello@lobbyspace.me</p>
        <p><b>Website</b><br />www.lobbyspace.me</p>
        <p><b>Telefon</b><br />040 22858947</p>
        <p>
          <b>Verantwortlich für redaktionelle Inhalte</b><br />Herr Samuel Härtl
        </p>
      </div>
      <div class="imprint__right">
        <p>
          <b>Umsatzsteuer-Identifikationsnummer<br /></b>DE323370794
        </p>
        <p>
          <b>Eintrag im Handelsregister</b><br />Amtsgericht Hamburg, HRB 156056
        </p>
        <p>
          <b>Haftung für Links<br /></b>Externe Links unserer Internetseite
          führen zu Inhalten fremder Anbieter. Für diese Inhalte ist allein der
          jeweilige Anbieter verantwortlich. Bei bekannt werden von
          Rechtsverletzungen werden diese Links umgehend entfernt.
        </p>
        <p>
          <b>Urheberrechtshinweis</b><br />Die durch uns erstellten Inhalte und
          Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers. Soweit die Inhalte
          auf dieser Seite nicht von uns selbst erstellt wurden, werden
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
          als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, wird um einen
          entsprechenden Hinweis gebeten. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.imprint {
  padding: 32px;
  max-width: 1000px;
  margin: 0 auto;

  &__title {
    @apply mb-4 text-4xl font-semibold text-center;
  }

  &__content {
    display: flex;
  }

  &__left,
  &__right {
    width: 50%;
    padding: 24px;
  }

  p {
    @apply mb-4;
  }
}
</style>
